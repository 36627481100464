import React, { Component } from 'react';
import ReactSelect from 'react-select';
import { randomId } from '../';
import '../styles.module.css';

/**
 * Select component
 * Form select component
 * @property {} options
 */
export default class Select extends Component {
  static defaultProps = {
    label: '',
    options: [],
    required: false,
    validationMessages: {
      valid: '',
      invalid: ''
    }
  };

  render() {
    const {
        options,
        label,
        required,
        validationMessages,
        status,
        className,
        ...attrs
      } = this.props,
      syncedId = randomId();

    return (
      <div className={className || ''}>
        <label className="label" styleName="label" htmlFor={syncedId}>
          {label}
          {required && ' *'}
        </label>
        <ReactSelect
          styleName="select"
          options={options}
          id={syncedId}
          required={required}
          classNamePrefix="select"
          {...attrs}
        />
        <span
          className="status"
          styleName="status"
          data-valid-message={validationMessages.valid}
          data-invalid-message={validationMessages.invalid}
        />
      </div>
    );
  }
}
