import { Modal as MaterialModal } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import React, { Component } from 'react';
import './styles.module.css';

/**
 * Modal component
 * Modal component constructed from Material UI
 * @property {} open
 */
export default class Modal extends Component {
  static defaultProps = {
    open: false,
    closeCallback: () => null
  };

  render() {
    const { open, closeCallback, className, children, ...attrs } = this.props;

    return (
      <MaterialModal
        styleName="modal"
        onBackdropClick={closeCallback}
        onEscapeKeyDown={closeCallback}
        open={open}
      >
        <Fade in={open}>
          <div styleName="modal-inner" className={className || ''} {...attrs}>
            {children}
          </div>
        </Fade>
      </MaterialModal>
    );
  }
}
