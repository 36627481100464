import React, { Component } from 'react';
import { Notification } from 'react-notification';
import hero from '../assets/img/hero.jpg';
import { ReactComponent as Logo } from '../assets/img/rastrum-logo.svg';
import Button from '../components/Button';
import Input from '../components/Form/Input';
import Select from '../components/Form/Select';
import Textarea from '../components/Form/Textarea';
import Page from '../components/Page';
import './styles.module.css';
import Modal from '../components/Modal';

const MAILCHIMP_ENDPOINT = `https://life.us12.list-manage.com/subscribe/post-json?u=9b575d616553748e9bbb39b38&amp;id=fdd66d8072&c=?`,
  MAILCHIMP_MAPPINGS = {
    honorific: 'MMERGE5',
    firstName: 'FNAME',
    lastName: 'LNAME',
    email: 'EMAIL',
    position: 'MMERGE2',
    affiliation: 'MMERGE6',
    message: 'MMERGE3'
  },
  HONORIFICS = ['Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.'],
  ERROR_NOTIFICATION = {
    message: `Something went wrong, please try again`,
    duration: 5000
  };

function formatHonorific(value) {
  return { value, label: value };
}

export default class LeadsPage extends Component {
  state = {
    formData: {},
    busy: false,
    modalOpen: false,
    isNotifying: false
  };

  updateFormData = (value, state) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [state]: value
      }
    }));
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  submit = e => {
    e.preventDefault();

    const { formData } = this.state,
      data = new FormData(),
      signupComplete = status => {
        this.setState({
          formData: {},
          busy: false
        });
        if (status === 'success') {
          this.setState({ modalOpen: true });
        } else {
          setTimeout(
            () => this.setState({ isNotifying: false }),
            ERROR_NOTIFICATION.duration
          );
        }
      };

    for (let field in formData) {
      data.append(MAILCHIMP_MAPPINGS[field], formData[field]);
    }

    this.setState({ busy: true });

    fetch(MAILCHIMP_ENDPOINT, {
      method: 'post',
      mode: 'no-cors',
      body: data
    })
      .then(() => signupComplete('success'))
      .catch(() => signupComplete('error'));
  };

  render() {
    const { busy, formData, modalOpen, isNotifying } = this.state;

    return (
      <Page
        title="RASTRUM by Inventia"
        description={'Learn more about RASTRUM by Inventia'}
        standalone={true}
      >
        <div styleName="page">
          <div styleName="page-inner">
            <section
              styleName="banner"
              style={{
                backgroundImage: `url(${hero}), linear-gradient(-180deg, #ec008c, #e6008c)`
              }}
            >
              <Logo styleName="logo" />
            </section>
            <section styleName="main">
              <h1 styleName="heading">Request a free virtual demo</h1>
              <p styleName="intro">Fill in your details below to request a virtual demo now.</p>
              <form styleName="form" onSubmit={this.submit}>
                <div styleName="fieldset">
                  <Select
                    styleName="input--honorific"
                    label="Honorific"
                    options={HONORIFICS.map(formatHonorific)}
                    value={formatHonorific(formData.honorific)}
                    onChange={e => this.updateFormData(e.value, 'honorific')}
                  />
                  <div styleName="name">
                    <Input
                      styleName="name-input"
                      label="First Name"
                      placeholder="John"
                      required={true}
                      value={formData.firstName || ''}
                      onChange={e =>
                        this.updateFormData(e.target.value, 'firstName')
                      }
                    />
                    <Input
                      styleName="name-input"
                      placeholder="Smith"
											label="Last Name"
                      required={true}
                      value={formData.lastName || ''}
                      onChange={e =>
                        this.updateFormData(e.target.value, 'lastName')
                      }
                    />
                  </div>
                </div>
                <Input
                  label="Email address"
                  placeholder="johnsmith@example.com"
                  type="email"
                  required={true}
                  value={formData.email || ''}
                  onChange={e => this.updateFormData(e.target.value, 'email')}
                />
                <div styleName="fieldset">
                  <Input
                    label="Position"
                    placeholder="Founder"
                    value={formData.position || ''}
                    onChange={e =>
                      this.updateFormData(e.target.value, 'position')
                    }
                  />
                  <Input
                    label="Affiliation"
                    placeholder="Acme, Inc"
                    value={formData.affiliation || ''}
                    required={true}
                    onChange={e =>
                      this.updateFormData(e.target.value, 'affiliation')
                    }
                  />
                </div>
                <Textarea
                  label="Comments/questions"
                  placeholder="Anything to add?"
                  value={formData.message || ''}
                  onChange={e => this.updateFormData(e.target.value, 'message')}
                />
                <Button styleName="submit" type="submit" busy={busy}>
                  Submit
                </Button>
              </form>
              <Modal
                styleName="modal"
                open={modalOpen}
                closeCallback={this.closeModal}
              >
                <Logo styleName="modal-logo" />
                <h1 styleName="modal-heading">Great, you're all done!</h1>
                <p styleName="modal-copy">
                  We will be in touch with you shortly to organise the <b>Virtual Demo</b> you requested.
                </p>
                <Button styleName="modal-button" onClick={this.closeModal}>
                  Great, thanks!
                </Button>
              </Modal>
              <Notification
                isActive={isNotifying}
                message={ERROR_NOTIFICATION.message}
              />
            </section>
          </div>
        </div>
      </Page>
    );
  }
}
